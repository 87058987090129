import React from "react"

function DownLoadIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" 
    className="downloadIcon"
    width="1em" 
    height="1em" 
    preserveAspectRatio="xMidYMid meet" 
    viewBox="0 0 512 512">
        <path 
        fill="currentColor" 
        d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32v242.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64h384c35.3 0 64-28.7 64-64v-32c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 104c-13.3 0-24-10.7-24-24s10.7-24 24-24s24 10.7 24 24s-10.7 24-24 24z"/>
        </svg>
  );
}

export default DownLoadIcon;